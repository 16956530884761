<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Type List</div>
						</div>
						<div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</div>
					<Divider></Divider>
                    <div class="grid">
                        <div class="col-12 md:col-4">
							<div class="field" >
								<label for="keyword">Keyword</label>
								<InputText
									class="w-full"
									id="keyword"
									v-model="keywordListValue"
									placeholder="Enter name or description"
								/>
							</div>
                        </div>
						<div class="col-12 md:col-4 md:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DataTable v-if="typeList.data != ''" ref="dt" :value="typeList.data" dataKey="id" responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:8%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" header="Name" headerStyle="width:20%; min-width:12rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="description" header="Description" headerStyle="width:60%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Description</span>
							{{slotProps.data.description}}
						</template>
					</Column>
					<Column header="Actions" headerStyle="width:12%; min-width:11rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="editOpen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="typeList.from" :rows="paginationValue" :totalRecords="typeList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No type found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="itemDialog" :style="{width: '550px'}" :header="Header" :modal="true" class="p-fluid" :closable="false">
                    <div class="field">
						<label for="name">Name</label>
						<InputText
							id="name"
							v-model="valid$.name.$model"
							required="true"
							placeholder="Enter Type Name"
							:class="{'p-invalid': valid$.name.$invalid && submitted}"
						/>
						<span v-if="valid$.name.$error && submitted">
							<span
								id="name-error"
								v-for="(error, index) of valid$.name.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.name.$invalid && submitted) || valid$.name.$pending.$response"
							class="p-error"
						>
							{{ valid$.name.required.$message.replace("Value", "Name") }}
						</small>
					</div>

					<div class="field">
						<label for="description">Description</label>
						<Textarea
							id="description"
							v-model="valid$.description.$model"
							:class="{'p-invalid': valid$.description.$invalid && submitted}"
							placeholder="Enter Description"
							:autoResize="true"
							
						/>
						<span v-if="valid$.description.$error && submitted">
							<span
								id="description-error"
								v-for="(error, index) of valid$.description.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Description")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.description.$invalid && submitted) || valid$.description.$pending.$response"
							class="p-error"
						>
							{{ valid$.description.required.$message.replace("Value", "Description") }}
						</small>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="Edit(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveType(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '550px'}" header="Confirm" :modal="true" :closable="false">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedItem">Are you sure you want to delete <b>{{selectedItem.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-raised p-button-danger" @click="Delete" />
					</template>
				</Dialog>

				<Dialog v-model:visible="showDialog" :style="{width: '550px'}" header="Type Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Description</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.description}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.created_at ? formatTime(selectedItem.created_at) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.updated_at ? formatTime(selectedItem.updated_at) : '-'}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';

export default {
	setup() {
		const toast = useToast();
		const router = useRouter();
		const route = useRoute();

		const loading = ref(false);
		const pageLoading = ref(true);
		const itemDialog = ref(false);
		const deleteDialog = ref(false);
		const showDialog = ref(false);
		const submitted = ref(false);

		const typeList = ref(null);
		const selectedItem = ref(null);
		const filters = ref({});
		const keywordListValue = ref();
		const paginationValue = ref(10);
		const pageValue = ref();
		const Header = ref('Create Type');

		const state = reactive({
			name : '',
			description : '',
		});

		const rules = {
			name : {required, maxLength: maxLength(255), minLength: minLength(3)},
			description : {required, maxLength: maxLength(255), minLength: minLength(3)},
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.keyword != null){
                keywordListValue.value = route.query.keyword;
            }
        };
        getActiveQuerys();

		//Type LIST
		const getTypelist = () =>{
			Axios
			.get("api/admin/type/list",{
				params: {
                    page: pageValue.value,
                    pagination: paginationValue.value,
                    keyword: keywordListValue.value,
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					typeList.value = res.data.data;
					typeList.value.from = typeList.value.from-1;
				}
				else{
					console.log(res.data.msg);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getTypelist();


		//OTHER FUNCTIONS
		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const openNew = () => {
			submitted.value = false;
			itemDialog.value = true;
		};

		const hideDialog = () => {
			itemDialog.value = false;
			showDialog.value = false;
			submitted.value = false;
            selectedItem.value = null;
            Header.value = 'Create Type';

            state.name = '';
            state.description = '';
		};

		//CREATE
		const saveType = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

            formData1.append("name", state.name);
			formData1.append("description", state.description);

			Axios
            .post("api/admin/type/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					itemDialog.value = false;
					getTypelist();
					state.name = '';
					state.description = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("error", "Error", err.message);
            });
		};

		//READ
		const confirmDetailSelected = (product) => {
			selectedItem.value = product;
			showDialog.value = true;
		};
		
		//DELETE
		const confirmDelete = (product) => {
			selectedItem.value = product;
			deleteDialog.value = true;
		};

		const Delete = () => {
			Axios
			.delete("api/admin/type/delete/"+selectedItem.value.id)
			.then(res => {
				if(res.data.response == "success"){
					showToast("success", "Success", res.data.message);
					getTypelist();
					deleteDialog.value = false;
				}
				else{
					showToast("error", "Error", res.data.message);
				}
			})
			.catch(err => {
				console.log(err);
			})
		};

		//EDIT OR UPDATE
		const editOpen = (product) => {
			selectedItem.value = product;
			itemDialog.value = true;
			Header.value = 'Edit Type';

            state.name = selectedItem.value.name;
            state.description = selectedItem.value.description;
		};

		const Edit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

            formData1.append("name", state.name);
			formData1.append("description", state.description);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/type/update/${selectedItem.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
                    selectedItem.value = null;
					itemDialog.value = false;
					getTypelist();
					state.name = '';
					state.description = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.msg);
                }
            })
            .catch((err) => {
                console.log(err);
                showToast("error", "Error", err.message);
            });
		};

		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(keywordListValue.value != null){
                queryParams.keyword = keywordListValue.value;
            }

            router.replace({ name: 'types', query: { ...queryParams } });

            getTypelist();
        };

        const clearFilters = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            keywordListValue.value = null;
            router.replace();

            getTypelist();
        };
		
		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'types', query: { ...queryParams } });

            getTypelist();
		}

		return{
			pageLoading,
			valid$,
			itemDialog,
			deleteDialog,
			showDialog,
			selectedItem,
			filters,
			submitted,
			typeList,
			saveType,
			loading,
			formatDate,
			formatTime,
			confirmDelete,
			Delete,
			editOpen,
			Edit,
			openNew,
			hideDialog,
			keywordListValue,
			paginationValue,
			clearFilters,
			getFilteredList,
			confirmDetailSelected,
			onPage,
			Header
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
